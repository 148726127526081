import React from 'react'
import { graphql } from 'gatsby'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

import Layout from '../components/layout'
import ProductDetails from '../components/productDetails'
import Seo from '../components/seo'
import ProductRecommendations from '../components/productRecommendations'
import Testimonial from '../components/testimonial'

import { productData } from '../components/productData'

export default function ProductPage({pageContext, data, location}) {
    const product = pageContext.productData
    product.images = data.allFile.edges.map(el => <GatsbyImage
        image={getImage(el.node)}
        alt={pageContext.productData.name + ' product pictures.'}
        className="w-full h-full object-center object-cover rounded-sm"
    />)

    const tmp = [...productData].filter(el => el.name !== product.name)
    let recos = [];

    for (let i = 0; i < 4; i++) {
        const index = Math.floor(Math.random() * tmp.length);
        const removed = tmp.splice(index, 1);
        recos.push(removed[0]);
    }
    
    return (
        <Layout>
            <Seo title={product.name + ' made by Sara Fashionwear'} />
            <ProductDetails product={product} activeNavigation={(!location.state || location.state.category === undefined) ? product.category : location.state.category}/>
            <Testimonial />
            <ProductRecommendations products={recos}/>
        </Layout>
    )
}

export const imageQuery = graphql`
query ImagesBySlug($slug: String!) {
    allFile(filter: {relativeDirectory: {eq: $slug}}) {
        edges {
            node {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    }
}        
`