import React from 'react'
import {Link, useStaticQuery, graphql} from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function ProductRecommendations({products}) {

    const data = useStaticQuery(
        graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "product-overview"}}) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                            fluid {
                                originalName
                            }
                        }
                    }
                }
            }
        }
        `
    )
    const imageData = data.allFile.edges

    products.forEach(el => {
        const correctImage = imageData[imageData.findIndex(i => i.node.childImageSharp.fluid.originalName === el.imageSrc)]
        el.image = correctImage.node
    });

    return (
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 mt-8 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-baseline sm:justify-between">
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
                    Explore more products
                </h2>
            </div>
            <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
                {products.map((product) => (
                    <div key={product.id} className="group relative pb-4">
                        <div className="w-full h-56 bg-gray-200 rounded-sm overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
                            <GatsbyImage
                                image={getImage(product.image)}
                                alt={product.imageAlt}
                                className="w-full h-full object-center object-cover"
                            />
                        </div>
                        <h3 className="mt-4 text-sm text-gray-700">
                            <Link to={'/products/' + product.slug}>
                                <span className="absolute inset-0" />
                                {product.name}
                            </Link>
                        </h3>
                        {/* <p className="mt-1 text-sm text-gray-500">{product.category}</p> */}
                        <p className="mt-1 text-sm font-medium text-gray-900">Explore</p>
                    </div>
                ))}
            </div>
        </div>
    )
}