import React, { useState } from 'react'
import {Link} from 'gatsby'
import { Disclosure, Tab } from '@headlessui/react'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
import { categoryData } from './categoryData'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductDetails({product, activeNavigation}) {

    const category = categoryData.find( el => el.name === product.category)

    return (
        <div >
            <div className="mx-auto py-16 sm:py-24 lg:max-w-7xl lg:px-8">
                <Link to={category.source === "inhouse" ? '/products' : '/sourcing'} state={{ scrollToProducts: true, category: activeNavigation ? activeNavigation : product.category }}>
                    <div className="md:px-32 text-sm font-semibold sm:block cursor-pointer pb-4 px-4 sm:px-6 lg:px-0">
                        <p className='text-gray-900 bg-primary-300 hover:bg-primary-500 w-fit px-2 py-1 rounded-sm'>
                            <span aria-hidden="true">&larr; </span>Back to products
                        </p>
                    </div>
                </Link>
                <div className=" lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                    {/* Image gallery */}
                    <Tab.Group as="div" className="hidden lg:flex flex-col-reverse" >
                        <div className="mt-6 w-full max-w-2xl mx-auto lg:block lg:max-w-none">
                            <Tab.List className="grid grid-cols-4 gap-6">
                                {product.images.map((image, index) => (
                                    <Tab
                                        key={index}
                                        className="relative h-24 bg-white rounded-sm flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none"
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span className="sr-only">{image.name}</span>
                                                <span className="absolute inset-0 rounded-sm overflow-hidden">
                                                    {image}
                                                </span>
                                                <span
                                                    className={classNames(
                                                        selected ? 'ring-dark-500' : 'ring-transparent',
                                                        'absolute inset-0 rounded-sm ring-1 ring-offset-0 pointer-events-none'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                        </div>

                        <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                            {product.images.map((image, index) => (
                                <Tab.Panel key={index}>
                                    {image}
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                    <Tab.Group as="div" className="lg:hidden flex flex-col-reverse " >
                        <div className='pr-1 '>
                            <div className="mt-1 w-full overflow-x-auto overflow-y-visible py-6">
                                <Tab.List className="grid grid-flow-col gap-4 px-4 sm:px-6 md:px-32">
                                    {product.images.map((image, index) => (
                                        <Tab
                                            key={index}
                                            className="relative h-24 w-24 bg-white rounded-sm flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none"
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span className="sr-only">{image.name}</span>
                                                    <span className="absolute inset-0 rounded-sm overflow-hidden">
                                                        {image}
                                                    </span>
                                                    <span
                                                        className={classNames(
                                                            selected ? 'ring-dark-500' : 'ring-transparent',
                                                            'absolute inset-0 rounded-sm ring-1 ring-offset-0 pointer-events-none'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </>
                                            )}
                                        </Tab>
                                    ))}
                                    <div className='w-1 -ml-1'></div>
                                </Tab.List>
                            </div>
                        </div>

                        <div className=" px-4 sm:px-6 md:px-32">
                            <Tab.Panels className="w-full aspect-w-1 aspect-h-1 ">
                                {product.images.map((image, index) => (
                                    <Tab.Panel key={index}>
                                        {image}
                                    </Tab.Panel>
                                ))}
                            </Tab.Panels>
                        </div>
                    </Tab.Group>

                    {/* Product info */}
                    <div className="w-full mt-4 px-4 sm:px-6 md:px-32 lg:px-6 sm:mt-8 lg:mt-0">
                        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">{product.name}</h1>

                        <div className="mt-3">
                            <h2 className="sr-only">Product information</h2>
                            <p className="text-2xl text-gray-900">{category.source[0].toUpperCase() + category.source.substring(1)}</p>
                        </div>

                        <div className="mt-6">
                            <h3 className="sr-only">Description</h3>
                            <div
                                className="text-base text-gray-700 space-y-6"
                            />
                            <p>{product.description}</p>
                        </div>
                        <p className='mt-4 text-gray-700 text-xs'>All design and brand rights are reserved to our clients. The purpose of all products on this page is strictly related to demonstrating our workmanship as an official manufacturer. </p>
                        <form className="mt-6">
                            <div className="mt-10 flex sm:flex-col1">
                                <a href='/contact'
                                    className="max-w-xs flex-1 pointer-cursor bg-dark-700 border border-transparent rounded-sm py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-dark-900 focus:outline-none sm:w-full"
                                >
                                    Get a quote
                                </a>
                            </div>
                        </form>

                        {product.details ? <section aria-labelledby="details-heading" className="mt-12">
                            <h2 id="details-heading" className="sr-only">
                                Additional details
                            </h2>

                            <div className="border-t divide-y divide-gray-200">
                                {product.details.map((detail) => (
                                    <Disclosure as="div" key={detail.name}>
                                        {({ open }) => (
                                            <>
                                                <h3>
                                                    <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                                                        <span
                                                            className={classNames(open ? 'text-primary-700' : 'text-gray-900', 'text-sm font-medium')}
                                                        >
                                                            {detail.name}
                                                        </span>
                                                        <span className="ml-6 flex items-center">
                                                            {open ? (
                                                                <MinusSmIcon
                                                                    className="block h-6 w-6 text-primary-500 group-hover:text-primary-700"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <PlusSmIcon
                                                                    className="block h-6 w-6 text-gray-500 group-hover:text-gray-700"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel as="div" className="pb-6 prose prose-sm">
                                                    <p className='text-small'>{detail.description}</p>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </div>
                        </section> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}